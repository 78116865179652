// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// custom components
// not supported for web -> should work now
import { ScrollIndicatorWrapper } from '../components/shared';

// services & config
import { appConfig, theme } from '../config';
import {Button, TextInput} from "react-native-paper";
import {Field, Formik, ErrorMessage} from "formik";
import * as Yup from 'yup';
// import {Input, Text} from "react-native-elements";
import {Input, Text} from "@rneui/themed";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function ErstmeldungScreen({navigation}) {
    const handleSubmit = (values) => {
        // Handle form submission
        console.log(values);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    let fields = [
        {
            'name': 'vater_name',
            'label': 'Vorname',
            'type': 'text',
        },
        {
            'name': 'vater_name',
            'label': 'Nachname',
            'type': 'text',
        },
        {
            'name': 'vater_geburtsdatum',
            'label': 'Geburtsdatum',
            'type': 'date',
        },
        {
            'name': 'vater_wohnort',
            'label': 'Wohnort',
            'type': 'text',
        },
        {
            'name': 'vater_plz',
            'label': 'PLZ',
            'type': 'text',
        },
        {
            'name': 'vater_strasse',
            'label': 'Straße und Hausnummer',
            'type': 'text',
        },
        {
            'name': 'vater_email',
            'label': 'E-Mail-Adresse',
            'type': 'text',
        },
        {
            'name': 'vater_telefon',
            'label': 'Telefonnummer',
            'type': 'text',
        },
    ];

    return (
        <ScrollIndicatorWrapper style={localStyle.wrapper}>
            <Formik
                initialValues={{ name: '', email: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <View style={{ padding: 20 }}>
                        <Text h4 style={{ marginBottom: 20 }}>
                            Erstmeldebogen
                        </Text>


                        <ErrorMessage name="name" component={Text} style={{ color: 'red', marginBottom: 10 }} />

                        {fields.map((item, index) => (
                            <Field
                                name={item.name}
                                component={Input}
                                placeholder={item.label}
                                inputContainerStyle={{ borderBottomWidth: 0 }}
                                containerStyle={{ marginBottom: 20 }}
                                label={item.label}
                                labelStyle={{ fontWeight: 'bold' }}
                            />
                        ))}


                        <ErrorMessage name="email" component={Text} style={{ color: 'red', marginBottom: 10 }} />

                        <Button title="Submit" onPress={handleSubmit} color="#007bff" />

                        {/* Or you can use a styled button from a styling library */}
                        {/* <Button title="Submit" onPress={handleSubmit} buttonStyle={{ backgroundColor: '#007bff' }} /> */}
                    </View>
                )}
            </Formik>
        </ScrollIndicatorWrapper>
    );
}

ErstmeldungScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        paddingTop: appConfig.scaleUiFkt(30),
        paddingBotton: appConfig.scaleUiFkt(20),
        paddingLeft: appConfig.scaleUiFkt(20),
        paddingRight: appConfig.scaleUiFkt(20),
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.values.defaultBackgroundColor,
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default ErstmeldungScreen;
