// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
import
***********************************************************************************************/

import React from 'react';
import {
  Text,
  StyleSheet,
  View,
  Modal,
  ActivityIndicator,
  Image,
} from 'react-native';

import { theme } from '../../config';

/***********************************************************************************************
 * component:
 * displays a fullscreen-loading animation (a simple spinner)
 ***********************************************************************************************/
function Spinner() {
  return (
    <View style={localStyle.container}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: 10,
          alignItems: 'center',
        }}
      >
        <Image
          source={require('../../assets/images/defaultLogo.png')}
          resizeMode="contain"
          style={localStyle.logo}
        ></Image>
        <ActivityIndicator
          color={theme.values.defaultSpinnerColor}
          size="large"
          style={localStyle.activityIndicator}
        />
        <Text
          style={{
            color: theme.values.defaultSpinnerColor,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          Einen Moment, wir übermitteln Ihre Daten.
          {'\n\n'}Gleich geht es weiter...
        </Text>
      </View>
    </View>
  );
}

/***********************************************************************************************
local styling
***********************************************************************************************/

const localStyle = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: theme.values.defaultSpinnerBackgroundColor,

    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
  },

  activityIndicator: {
    alignSelf: 'center',
    height: 50,
    width: 50,
  },

  logo: {
    top: 0,
    height: 100,
    width: 200,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default Spinner;
