// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useState} from 'react';
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';

// services & config
import {appConfig, theme} from '../../config';
import translate from '../../services/localization';
import AppIntroSlider from '../../components/shared/AppIntroSlider';
import {ScrollIndicatorWrapper} from '../../components/shared';

/***********************************************************************************************
 * component
 ***********************************************************************************************/
function WalkthroughView({onDone, onSkip}) {

    const [page, setPage] = useState(0);


    let _renderItem = (item) => {
        return (
            <ScrollIndicatorWrapper>
                <View style={localStyle.slide}>
                    {!!item.image && (
                        <Image source={item.image.uri} style={localStyle.image}/>
                    )}
                    <Text style={localStyle.title}>{item.title}</Text>
                    <Text style={localStyle.text}>{item.text}</Text>
                </View>
            </ScrollIndicatorWrapper>
        );
    };

    let _renderNextButton = () => {
        return (
            <View>
                <Image
                    source={require('src/assets/images/schrittWeiter.png')}
                    style={localStyle.btnNext}
                />
            </View>
        );
    };
    let _renderPrevButton = () => {
        return (
            page > 0 ?
            <View>
                <Image
                    source={require('src/assets/images/schrittZurueck.png')}
                    style={localStyle.btnNext}
                />
            </View> : <></>
        );
    };
    let _renderSkipButton = () => {
        return (
            <View>
                <Text style={localStyle.skipText}>Überspringen</Text>
            </View>
        );
    };

    let _renderDoneButton = () => {
        return (
            <TouchableOpacity
                onPress={() => {
                    if (Platform.OS === 'native') {
                        slider.goToSlide(0);
                    } else {
                        setPage(0);
                    }

                    onDone();
                }}
            >
                <Image
                    source={require('src/assets/images/schrittCheck.png')}
                    style={localStyle.btnNext}
                />
            </TouchableOpacity>
        );
    };

    let slider = undefined;

    const slides = [
        {
            key: 'k1',
            title: translate('walkthrough').k1.title,
            text: translate('walkthrough').k1.text,
            image: {
                uri: require('src/assets/images/illu-rundgang.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k2',
            title: translate('walkthrough').k2.title,
            text: translate('walkthrough').k2.text,
            image: {
                uri:
                    require('src/assets/images/scrshot-familie.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.imageScrshot,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k3',
            title: translate('walkthrough').k3.title,
            text: translate('walkthrough').k3.text,
            image: {
                uri: require('src/assets/images/scrshot-info.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.image,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k4',
            title: translate('walkthrough').k4.title,
            text: translate('walkthrough').k4.text,
            image: {
                uri: require('src/assets/images/illu-laborwerte.png'),
            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.image,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k5',
            title: translate('walkthrough').k5.title,
            text: translate('walkthrough').k5.text,
            image: {
                uri: require('src/assets/images/illu-folgemeldung.png'),

            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.image,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k6',
            title: translate('walkthrough').k6.title,
            text: translate('walkthrough').k6.text,
            image: {
                uri: require('src/assets/images/illu-laborwerte.png'),
            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.image,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k7',
            title: translate('walkthrough').k7.title,
            text: translate('walkthrough').k7.text,
            image: {
                uri: require('src/assets/images/scrshot-overview.png'),
            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.imageScrshot,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k8',
            title: translate('walkthrough').k8.title,
            text: translate('walkthrough').k8.text,
            image: {
                uri: require('src/assets/images/scrshot-menu.png'),
            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.imageScrshot,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k9',
            title: translate('walkthrough').k9.title,
            text: translate('walkthrough').k9.text,
            image: {
                uri: require('src/assets/images/scrshot-feedback.png'),
            },

            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            imageStyle: localStyle.imageScrshot,
            backgroundColor: theme.colors.primary,
        }
    ];

    return (
        <>
            {Platform.OS === 'native' &&
                <AppIntroSlider
                    data={slides}
                    ref={(ref) => (slider = ref)}
                    renderItem={_renderItem}
                    showSkipButton={false}
                    showPrevButton={true}
                    renderNextButton={_renderNextButton}
                    renderPrevButton={_renderPrevButton}
                    renderDoneButton={_renderDoneButton}
                    onSkip={onSkip}
                    dotStyle={{backgroundColor: 'rgba(0, 0, 0, .2)'}}
                    activeDotStyle={{backgroundColor: theme.colors.primary}}
                />}
            {Platform.OS === 'web' &&
                <View>
                    <View style={localStyle.wrapper}>

                        <View>
                            {_renderItem(slides[page])}
                        </View>

                        <View style={{flexDirection: 'row', width: 200, alignSelf: 'flex-end', marginTop: 40}}>
                            <TouchableOpacity style={{flex: 1}} onPress={() => setPage(page - 1)}>
                                {_renderPrevButton()}
                            </TouchableOpacity>

                            {page < slides.length - 1 &&
                                <TouchableOpacity onPress={() => setPage(page + 1)}>
                                    {_renderNextButton()}
                                </TouchableOpacity>
                            }
                            {page === slides.length - 1 &&
                                <TouchableOpacity style={{align: 'flex-end'}} onPress={() => {
                                }}>
                                    {_renderDoneButton()}
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </View>}
        </>
    );
}

WalkthroughView.propTypes = {
    onDone: PropTypes.func,
    onSkip: PropTypes.func,
};

WalkthroughView.defaultProps = {
    onDone: () => {
    }, onSkip: () => {
    }
};

/***********************************************************************************************
 local styling
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        flex: 1,
        width: 700,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    },
    title: {
        fontSize: theme.fonts.title2.fontSize,
        // color: theme.colors.success,
        fontWeight: 'bold',
        textAlign: 'left',
        marginTop: 10,
    },
    text: {
        color: theme.colors.accent4,
        fontSize: theme.fonts.header3.fontSize,
        // textAlign: "justify",
        paddingTop: 30
    },
    skipText: {
        color: theme.colors.success,
        fontFamily: theme.fonts.header3.fontFamily,
        fontSize: theme.fonts.header3.fontSize,
        marginTop: 8
    },
    buttonText: {
        color: theme.colors.success,
        fontSize: theme.fonts.header2.fontSize,
    },
    btnNext: {
        width: 44,
        height: 44,
        resizeMode: 'contain'
    },
    image: {
        width: '100%',
        height: 250,
        resizeMode: 'contain',
        justifyContent: 'center',
    },
    slide: {
        paddingStart: appConfig.scaleUiFkt(15)
    },
    buttonCircle: {
        width: appConfig.scaleUiFkt(40),
        height: appConfig.scaleUiFkt(40),
        backgroundColor: theme.colors.success,
        borderRadius: appConfig.scaleUiFkt(20),
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default WalkthroughView;
