import * as React from "react";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import { theme } from '../../config';

const DashboardButton = ({text, onPress, img, rotated = false}) => (

    <TouchableOpacity
        onPress={onPress}
        style={[styles.mainButton]}
    >
        {img &&
            <View
                style={styles.iconBorder}>
                <Image
                    source={img}
                    resizeMode="contain"
                    style={[styles.icon, rotated ? styles.icon_rotated : null]}
                ></Image>
            </View>}

        <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
);

const styles = StyleSheet.create({

    mainButton: {
        width: 130,
        height: 140,

        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        margin: 10
    },
    icon: {
        height: 50,
        width: 50,
    },
    icon_rotated: {
        transform: [{ rotate: '180deg' }],
    },
    iconBorder: {
        justifyContent: 'center', //Centered vertically
        alignItems: 'center', //Centered horizontally

        height: 80,
        width: 80,
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: 34,
        borderWidth: 1,
        borderColor: theme.colors.primary,
    },
    buttonText: {
        color: theme.colors.acc,
        fontSize: 18,
        marginTop: 5,
        textAlign: "center"
    },
});

export default DashboardButton;
