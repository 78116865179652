import * as React from "react";
import {Dimensions, Image, Platform, StyleSheet, View} from "react-native";
import StartScreenButton from "../../components/ui/StartScreenButton";
import ReactNativeBiometrics, {BiometryTypes} from "react-native-biometrics";
import {theme} from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setIsBiometryAuthenticated} from "../../store/globals.slice";


const AppLock = () => {
    const dispatch = useDispatch();

    const { isBiometryAuthenticated } = useSelector((state) => state.Globals);
    const { useAppLock } = useSelector((state) => state.User);
    useEffect(() => {
        setAppLock(useAppLock);
    }, [useAppLock]);
    
    const [appLock, setAppLock] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const biometricsPrompt = () => {
        if (isAuthenticated) {
            return;
        }

        const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials: true});

        rnBiometrics.isSensorAvailable()
            .then((resultObject) => {
                const {available, biometryType} = resultObject

                let promptMessage = '';
                if (available && biometryType === BiometryTypes.TouchID) {
                    promptMessage = 'Mit Touch ID entsperren';
                } else if (available && biometryType === BiometryTypes.FaceID) {
                    promptMessage = 'Mit FaceID entsperren';
                } else if (available && biometryType === BiometryTypes.Biometrics) {
                    promptMessage = 'Mit Fingerabdruck entsperren';
                } else {
                    console.log('Biometrics not supported')
                }

                let fallbackPromptMessage = 'Geben Sie Ihre PIN ein';
                if (Platform.OS === 'ios') {
                    fallbackPromptMessage = 'Geben Sie Ihren Passcode ein';
                }

                if (available) {
                    rnBiometrics.simplePrompt({
                        promptMessage: promptMessage,
                        fallbackPromptMessage: fallbackPromptMessage,
                        cancelButtonText: 'Abbruch',
                    })
                        .then((resultObject) => {
                            const {success} = resultObject

                            if (success) {
                                dispatch(setIsBiometryAuthenticated(true));
                                console.log('successful biometrics provided')
                            } else {
                                console.log('user cancelled biometric prompt')
                            }
                        })
                        .catch((e) => {
                            console.log('biometrics failed')
                            console.error(e)
                        })
                }
            })
    }

useEffect(() => {
        setIsAuthenticated(isBiometryAuthenticated);
        if (!isBiometryAuthenticated) {
            biometricsPrompt();
        }
    }, [isBiometryAuthenticated]);

    return (
        <View style={localStyle.wrapper}>
            <Image
                source={require("../../assets/images/illu-einfuehrung1.png")}
                resizeMode="contain"
                style={localStyle.logo}
            ></Image>

            <View style={{height: 120}}>
                <StartScreenButton
                    onPress={() => {
                        biometricsPrompt()
                    }}
                    title={"App ist gesperrt"}
                    text={"Zum entsperren hier tippen"}
                />
            </View>
        </View>
    )
};

const windowWidth = Dimensions.get("window").width;

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    },
    logo: {
        width: windowWidth,
        aspectRatio: 1.48289,
        height: windowWidth / 1.48289,
        backgroundColor: 'white',
        alignSelf: 'center'
    },
});

export default AppLock;
