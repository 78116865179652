import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';

import memoize from 'lodash.memoize';

import translate from '../../services/localization';
import { theme } from '../../config';
import DashboardButton from '../../components/survey/DashboardButton';
// import {ListItem} from "react-native-elements";
import { ListItem } from '@rneui/themed';
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';

/**
 * depending on the state of the given category an accessibility hint is built from the strings defined in the config file
 * @param {boolean} done whether the category has been completely answered as required
 * @param {boolean} started whether the category has been started
 * @returns {string} a string as accessibility hint describing the state of the category
 */
const getAccessibilityHint = (done, started) => {
  let hint = translate('accessibility').questionnaire.categoryCellHint;
  if (done) {
    return (hint +=
      translate('accessibility').questionnaire.category +
      translate('accessibility').questionnaire.finished);
  }
  if (started) {
    return (hint +=
      translate('accessibility').questionnaire.category +
      translate('accessibility').questionnaire.notFinished);
  }

  return (hint +=
    translate('accessibility').questionnaire.category +
    translate('accessibility').questionnaire.notStarted);
};

/**
 * depending on the state of the given category an accessibility hint is built from the strings defined in the config file
 * @param {boolean} done whether the category has been completely answered as required
 * @param {boolean} started whether the category has been started
 * @returns {{name: string, color : string}} an object describing properties of the chevron
 */
const getCategoryChevronProps = (done, started) => {
  if (done) {
    return {
      name: 'check-circle',
      color: theme.values.defaultSurveyIconCompletedColor,
    };
  }
  if (started) {
    return {
      name: 'chat-question',
      color: theme.values.defaultSurveyIconTouchedColor,
    };
  }
  return {
    name: 'chat-question',
    color: theme.values.defaultSurveyIconUntouchedColor,
  };
};

/***********************************************************************************************
 * component
 * renders the list of categories, i.e. the first-level items
 *
 * @param  {object}    props
 * @param  {[QuestionnaireItem]}   props.categories indicates whether the current category has been completely answered
 * @param  {object<string, QuestionnaireItem>}   props.itemMap
 * @param  {function} props.showQuestionnaireModal callback to open the modal at the chosen category
 */
function CategoriesList({
  categories,
  itemMap,
  showQuestionnaireModal,
  FHIRmetadata,
}) {
  // memoize determination of chevronProps

  const chevronProps = memoize(
    getCategoryChevronProps,
    (done, started) => `${done}_${started}`,
  );

  // memoize determination of accessibility hint
  const a11yHint = memoize(
    getAccessibilityHint,
    (done, started) => `${done}_${started}`,
  );

  // internal state to control which category is expanded
  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleAccordion = (index) => {
    if (expandedCategory === index) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(index);
    }
  };

  const { lastOpenedCategoryIndex, lastOpenedPageIndex } = useSelector(
    (state) => state.Questionnaire,
  );

  const { lastSubmitSingle } = useSelector((state) => state.Globals);

  let [alertCanceled, setAlertCanceled] = useState(
    lastOpenedCategoryIndex == -1 && lastOpenedPageIndex == 0,
  );

  if (
    !categories?.[lastOpenedCategoryIndex]?.item[0]?.fieldAnnotation?.includes(
      '[buttonSurvey',
    )
  ) {
    if (!alertCanceled) {
      setAlertCanceled(true);
      Alert.alert(
        translate('accessibility').questionnaire.continueFromLastTitle,
        translate('accessibility').questionnaire.continueFromLast,
        [
          {
            text: translate('accessibility').questionnaire.continueFromLastNo,
            style: 'cancel',
            onPress: () => {
              setAlertCanceled(true);
            },
          },
          {
            text: translate('accessibility').questionnaire.continueFromLastYes,
            style: 'default',
            onPress: () => {
              setAlertCanceled(true);
              showQuestionnaireModal(
                lastOpenedCategoryIndex,
                lastOpenedPageIndex,
              );
            },
          },
        ],
        { cancelable: false },
      );
    }
  }

  if (categories) {
    let i = 1;
    return (
      <View style={localStyle.wrapper}>
        {/* create accordion listItem for each category (i.e. first level item)*/}
        {categories.map((category, categoryIndex) => {
          // get additional properties based on the completion state of the category
          const { done: categoryDone, started: categoryStarted } =
            itemMap[category.linkId];

          console.log(category.text);

          //console.log('category.scheduleTimes');
          // console.log(category.scheduleTimes);

          const now = new Date();
          const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            0,
            0,
            0,
          );

          let show = false;
          let doFillOut = true;
          if (!category.scheduleTimes || category.scheduleTimes.length == 0) {
            show = true;
          } else {
            for (let t = 0; t < category.scheduleTimes.length; t++) {
              let start = category.scheduleTimes[t].start;
              let end = category.scheduleTimes[t].end;
              const [startHour, startMinute] = start.split(':').map(Number);
              const [endHour, endMinute] = end.split(':').map(Number);
              let startTime = new Date(
                today.getTime() +
                  startHour * 60 * 60 * 1000 +
                  startMinute * 60 * 1000,
              );
              let endTime = new Date(
                today.getTime() +
                  endHour * 60 * 60 * 1000 +
                  endMinute * 60 * 1000,
              );
              show = show || (now <= endTime && now >= startTime);

              if (lastSubmitSingle?.[category?.instrument_name]) {
                if (
                  lastSubmitSingle[category?.instrument_name] > startTime &&
                  lastSubmitSingle[category?.instrument_name] < endTime
                ) {
                  doFillOut = false;
                }
              }

              console.log(startTime);
              console.log(endTime);
            }
          }

          console.log(lastSubmitSingle);
          if (category.item[0]?.fieldAnnotation.includes('[buttonSurvey')) {
            return show ? (
              <>
                <DashboardButton
                  onPress={() => showQuestionnaireModal(categoryIndex)}
                  solid={category.scheduleTimes.length > 0 && doFillOut}
                  text={
                    !!FHIRmetadata.hideTitlesInOverview
                      ? 'Fragebogen ' + i++
                      : category.text
                  }
                />
                {category.scheduleTimes.length > 0 && doFillOut && (
                  <Text style={{ textAlign: 'center' }}>
                    Bitte jetzt ausfüllen
                  </Text>
                )}
                {!!lastSubmitSingle?.[category?.instrument_name] && (
                  <Text style={{ textAlign: 'center' }}>
                    Zuletzt ausgefüllt:{' '}
                    {lastSubmitSingle?.[
                      category?.instrument_name
                    ]?.toLocaleString('de-DE', { timeZone: 'UTC' })}
                  </Text>
                )}
                {!lastSubmitSingle?.[category?.instrument_name] && (
                  <Text style={{ textAlign: 'center' }}>
                    Bislang nicht ausgefüllt
                  </Text>
                )}
              </>
            ) : (
              <></>
            );
          } else {
            return (
              <ListItem.Accordion
                content={
                  <TouchableOpacity
                    style={localStyle.accordionContent}
                    onPress={() => showQuestionnaireModal(categoryIndex)}
                  >
                    <ListItem.Chevron
                      type="material-community"
                      name={chevronProps(categoryDone, categoryStarted).name}
                      size={18}
                      reverse
                      color={chevronProps(categoryDone, categoryStarted).color}
                      testID={`${category.linkId}_icon`}
                    />
                    <ListItem.Content>
                      {!!FHIRmetadata.hideTitlesInOverview && (
                        <ListItem.Title style={localStyle.titleStyle}>
                          Fragebogen {i++}
                        </ListItem.Title>
                      )}
                      {!FHIRmetadata.hideTitlesInOverview && (
                        <ListItem.Title style={localStyle.titleStyle}>
                          {category.text}
                        </ListItem.Title>
                      )}
                    </ListItem.Content>
                  </TouchableOpacity>
                }
                isExpanded={categoryIndex === expandedCategory}
                key={category.linkId}
                containerStyle={localStyle.accordionContainer}
                icon={{
                  name: 'expand-more',
                  size: 30,
                  onPress: () => toggleAccordion(categoryIndex),
                  accessibilityHint:
                    categoryIndex !== expandedCategory
                      ? translate('accessibility').questionnaire.expandCategory
                      : translate('accessibility').questionnaire
                          .collapseCategory,
                  accessibilityRole: translate('accessibility').types.button,
                }}
                accessibilityLabel={category.text}
                accessibilityRole={translate('accessibility').types.button}
                accessibilityHint={a11yHint(categoryDone, categoryStarted)}
              >
                {/* when category is expanded list items of that category */}
                {categoryIndex === expandedCategory &&
                  category.item.map((categoryItem, pageIndex) => {
                    const { done: itemDone, started: itemStarted } =
                      itemMap[categoryItem.linkId];
                    const itemText =
                      categoryItem.type != 'display'
                        ? categoryItem.text.replace(/(<([^>]+)>)/gi, '')
                        : '(Anleitung)';
                    // only display item when dependencies are met
                    return questionnaireAnalyzer.checkConditionsOfSingleItem(
                      categoryItem,
                      itemMap,
                    ) &&
                      !questionnaireAnalyzer.itemIsEmbedded(
                        categoryItem,
                        itemMap,
                      ) ? (
                      <ListItem
                        key={categoryItem.linkId}
                        containerStyle={[localStyle.listItemContainer]}
                        onPress={() =>
                          showQuestionnaireModal(categoryIndex, pageIndex + 1)
                        }
                      >
                        {categoryItem.type != 'display' ? (
                          <ListItem.Chevron
                            type="material-community"
                            name={chevronProps(itemDone, itemStarted).name}
                            size={30}
                            color={chevronProps(itemDone, itemStarted).color}
                            testID={`${category.linkId}_icon`}
                          />
                        ) : (
                          <ListItem.Chevron
                            type="material-community"
                            size={30}
                            name={'file-document'}
                            color={theme.colors.success}
                          />
                        )}
                        {/* title */}
                        <ListItem.Content>
                          <ListItem.Title style={localStyle.itemTitleStyle}>
                            {itemText}
                          </ListItem.Title>
                        </ListItem.Content>
                      </ListItem>
                    ) : null;
                  })}
              </ListItem.Accordion>
            );
          }
        })}
      </View>
    );
  }
  return <View />;
}

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      linkId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ),
  itemMap: PropTypes.objectOf(
    PropTypes.shape({ linkId: PropTypes.string, text: PropTypes.string }),
  ),
  showQuestionnaireModal: PropTypes.func.isRequired,
};

CategoriesList.defaultProps = {
  categories: null,
  itemMap: null,
};

const localStyle = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  accordionContainer: {
    backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
    width: '100%',
  },
  accordionContent: {
    flex: 1,
    paddingLeft: 0,
    flexDirection: 'row',
    backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
  },

  listItemContainer: {
    width: '100%',
    borderBottomColor: theme.colors.accent1,
    borderBottomWidth: 1,
    backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
    paddingLeft: 17,
  },

  titleStyle: {
    ...theme.fonts.header2,
    paddingLeft: 14,
    color: theme.values.defaultSurveyItemTitleColor,
  },

  itemTitleStyle: {
    ...theme.fonts.header3,
    color: theme.values.defaultSurveyItemTitleColor,
  },
});

export default CategoriesList;
