// this is a mock Version Check return, because react-native-version-check is not supported on web

// TODO: check if this is valid because current version and build number are set manually 
function VersionCheck() {
    const currentVersion = "2.0"
    const currentBuildNumber = "2.0"

    function getCurrentVersion() {
        return currentVersion;
    }

    function getCurrentBuildNumber() {
        return currentBuildNumber;
    }
}

export default VersionCheck;