import { AppRegistry } from 'react-native';
import App from './App'; 
import appName from './appName.json';

// Use the prebuilt version of RNVI located in the dist folder
import Icon from 'react-native-vector-icons/dist/FontAwesome';

// Generate the required CSS
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: FontAwesome;
}
@font-face {
    src: url(${MaterialIcons});
    font-family: "MaterialIcons";
}
@font-face {
    src: url(${MaterialCommunityIcons});
    font-family: "MaterialCommunityIcons";
}
`;

// Create a stylesheet
const style = document.createElement('style');
style.type = 'text/css';

// Append the iconFontStyles to the stylesheet
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject the stylesheet into the document head
document.head.appendChild(style);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  // Mount the react-native app in the "root" div of index.html
  rootTag: document.getElementById('root'),
});
