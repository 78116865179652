// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

// this file contains functions that interact with the AsyncStorage that replaces the EncryptedStorage in web mode.

/***********************************************************************************************
imports
***********************************************************************************************/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { appConfig } from '../config';

// kiosk mode
/*-----------------------------------------------------------------------------------*/

/**
 * loads the kiosk mode data from the AsyncStorage
 * @returns string | null
 */
const loadKioskModeData = async () => {
  try {
    return await AsyncStorage.getItem(appConfig.kioskModeData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * clears the AsyncStorage
 */
const clear = async () => {
  try {
    await AsyncStorage.clear();
  } catch (error) {
    console.error(error);
  }
};

/**
 * persists the current kiosk mode data
 * @param  {string} [kioskModeData] kioskModeData of the user
 */
const persistKioskModeData = async (kioskModeData) => {
  const id = kioskModeData || (await loadKioskModeData());
  if (!id) return;

  try {
    await AsyncStorage.setItem(appConfig.kioskModeData, id);
  } catch (error) {
    console.error(error);
  }
};

/**
 * deletes the kiosk mode data from the AsyncStorage
 */
const removeKioskModeData = async () => {
  try {
    await AsyncStorage.removeItem(appConfig.kioskModeData);
  } catch (error) {
    console.error(error);
  }
};

/**
 * save the language chosen by the user
 * @param  {string} languageCode languageCode of the questionnaire
 */
const persistUserLanguage = async (languageCode) => {
  try {
    await AsyncStorage.setItem(appConfig.userLanguage, languageCode);
  } catch (error) {
    console.error(error);
  }
};

const persistIsRegistered = async (isReg) => {
  try {
    await AsyncStorage.setItem(appConfig.isRegistered, isReg ? '1' : '0');
  } catch (error) {
    console.error(error);
  }
};


const persistShowStartView = async (show) => {
  try {
    await AsyncStorage.setItem(appConfig.showStartView, show ? '1' : '0');
  } catch (error) {
    console.error(error);
  }
};

/**
 * loads the language chosen by the user
 * @param  {string} [subjectId] subject-id
 * @returns string | null
 */
const loadUserLanguage = async () => {
  try {
    return await AsyncStorage.getItem(appConfig.userLanguage);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getIsRegistered = async () => {
  try {
    return (await AsyncStorage.getItem(appConfig.isRegistered)) === '1';
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getShowStartView = async () => {
  try {
    return await AsyncStorage.getItem(appConfig.showStartView) === "1";
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * deletes the language chosen by the user
 */
const removeUserLanguage = async () => {
  try {
    await AsyncStorage.removeItem(appConfig.userLanguage);
  } catch (error) {
    console.error(error);
  }
};

/***********************************************************************************************
export
***********************************************************************************************/

export default {
  loadKioskModeData,
  persistKioskModeData,
  removeKioskModeData,
  clear,
  loadUserLanguage,
  persistUserLanguage,
  removeUserLanguage,
  getIsRegistered,
  persistIsRegistered,
  getShowStartView,
  persistShowStartView
};
