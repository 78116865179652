// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useState} from 'react';
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';

// services & config
import {appConfig, theme} from '../../config';
import AppIntroSlider from '../../components/shared/AppIntroSlider';
import {ScrollIndicatorWrapper} from '../../components/shared';

/***********************************************************************************************
 * component
 ***********************************************************************************************/
function StartView({onDone, onSkip}) {

    const [page, setPage] = useState(0);

    let _renderItem = (item) => {
        return (
            <ScrollIndicatorWrapper>
                <View style={localStyle.slide}>
                    {!!item.image && <Image source={item.image.uri} style={localStyle.image}/>}
                    <Text style={localStyle.title}>{item.title}</Text>
                    <Text style={localStyle.text}>{item.text}</Text>
                </View>
            </ScrollIndicatorWrapper>
        );
    }

    let _renderNextButton = () => {
        return (
            <View>
                <Image source={require('src/assets/images/schrittWeiter.png')} style={localStyle.btnNext}/>
            </View>
        );
    };
    let _renderPrevButton = () => {
        return (
            page > 0 ?
                <View>
                    <Image source={require('src/assets/images/schrittZurueck.png')} style={localStyle.btnNext}/>
                </View> : <></>
        );
    };
    let _renderSkipButton = () => {
        return (
            <View>
                <Text style={localStyle.skipText}>Überspringen</Text>
            </View>
        );
    };

    let _renderDoneButton = () => {
        return (
            <TouchableOpacity onPress={onDone}>
                <Image
                    source={require('src/assets/images/schrittCheck.png')}
                    style={localStyle.btnNext}
                />
            </TouchableOpacity>
        );
    };

    const slides = [
        {
            key: 'k1',
            title: 'Herzlich willkommen',
            // text: translate('start').k1.text,
            text: 'Wir freuen uns, Sie in der Alpha-1-KIDS-App, dem Register für Kinder und Jugendliche mit Alpha-1-Antitrypsin-Mangel, begrüßen zu dürfen.\n\nDie Meldung erfolgt über die Familien selbst. Die Teilnahme ist sowohl über das Smartphone mittels App (empfohlen) als auch am PC über diese Homepage möglich.',
            image: {
                uri:
                    require('src/assets/images/illu-einfuehrung1.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k2',
            // title: translate('start').k3.title,
            title: 'So funktioniert es...',
            // text: translate('start').k3.text,
            text: 'Um die Sicherheit Ihrer Daten im Register zu gewährleisten, müssen Sie sich in zwei Schritten registrieren.\n\n1. Schritt: Wir benötigen Ihren Namen und Ihre Anschrift, damit wir Ihnen per Post einen Zugangscode zusenden können.',
            image: {
                uri: require('src/assets/images/illu-einfuehrung3.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            backgroundColor: theme.colors.primary,
        },
        {
            key: 'k3',
            // title: translate('start').k3.title,
            title: 'So funktioniert es...',
            // text: translate('start').k3.text,
            text: '2. Schritt: Scannen Sie den per Post zugesendeten QR-Code ein oder geben Sie den Zahlencode ein.\n\nNun ist die Registrierung abgeschlossen und Sie können mit der Dateneingabe starten.',
            image: {
                uri: require('src/assets/images/illu-einfuehrung4.png'),
            },
            titleStyle: localStyle.title,
            textStyle: localStyle.text,
            backgroundColor: theme.colors.primary,
        }
    ];


    return (
        <>
            {Platform.OS === 'native' &&
                <AppIntroSlider data={slides}
                                renderItem={_renderItem}

                                showSkipButton={false}
                                showPrevButton={true}

                                renderNextButton={_renderNextButton}
                                renderPrevButton={_renderPrevButton}
                                renderDoneButton={_renderDoneButton}

                                dotStyle={{backgroundColor: 'rgba(0, 0, 0, .2)'}}
                                activeDotStyle={{backgroundColor: theme.colors.primary}}

                />}
            {Platform.OS === 'web' &&
                <View>
                    <View style={localStyle.wrapper}>

                        <View>
                            {_renderItem(slides[page])}
                        </View>

                        <View style={{flexDirection: 'row', width: 200, alignSelf: 'flex-end', marginTop: 40}}>
                            <TouchableOpacity style={{flex: 1}} onPress={() => setPage(page - 1)}>
                                {_renderPrevButton()}
                            </TouchableOpacity>

                            {page < slides.length - 1 &&
                                <TouchableOpacity onPress={() => setPage(page + 1)}>
                                    {_renderNextButton()}
                                </TouchableOpacity>
                            }
                            {page === slides.length - 1 &&
                                <TouchableOpacity style={{align: 'flex-end'}} onPress={() => {
                                }}>
                                    {_renderDoneButton()}
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </View>}
        </>
    );
}

StartView.propTypes = {
    onDone: PropTypes.func,
    onSkip: PropTypes.func
};

StartView.defaultProps = {
    onDone: () => {
    }, onSkip: () => {
    }
};

/***********************************************************************************************
 local styling
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        flex: 1,
        width: 700,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    },
    title: {
        fontSize: theme.fonts.title2.fontSize,
        // color: theme.colors.success,
        fontWeight: 'bold',
        textAlign: 'left',
        marginTop: 10,
    },
    text: {
        color: theme.colors.accent4,
        fontSize: theme.fonts.header3.fontSize,
        // textAlign: "justify",
        paddingTop: 30
    },
    skipText: {
        color: theme.colors.success,
        fontFamily: theme.fonts.header3.fontFamily,
        fontSize: theme.fonts.header3.fontSize,
        marginTop: 8
    },
    buttonText: {
        color: theme.colors.success,
        fontSize: theme.fonts.header2.fontSize,
    },
    btnNext: {
        width: 44,
        height: 44,
        resizeMode: 'contain'
    },
    image: {
        width: '100%',
        height: 250,
        resizeMode: 'contain',
        justifyContent: 'center',
    },
    slide: {
        paddingStart: appConfig.scaleUiFkt(15)
    },
    buttonCircle: {
        width: appConfig.scaleUiFkt(40),
        height: appConfig.scaleUiFkt(40),
        backgroundColor: theme.colors.success,
        borderRadius: appConfig.scaleUiFkt(20),
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default StartView;
