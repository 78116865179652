// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useRef, useState} from 'react';
import {Modal, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View,} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// custom components
import {ScrollIndicatorWrapper} from '../components/shared';

// services & config
import translate from '../services/localization';
import {appConfig, theme} from '../config';
import {Portal, Provider, RadioButton, TextInput} from 'react-native-paper';
import {loggedInClient} from '../services/rest';
import validator from 'validator';
import RenderHtml from 'react-native-render-html';
import {setIsRegistered} from "../store/globals.slice";
import DropDownPicker from "react-native-dropdown-picker";
// import { CheckBox } from 'react-native-elements';
import {CheckBox} from '@rneui/themed';
import SharedStyles from '../components/questionnaireModal/input/sharedStyles';
import {useDispatch} from 'react-redux';
import showMessage from '../components/shared/showMessage';

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function MedicalStaffScreen({navigation}) {

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const countryPickerItems = [{label: "Deutschland", value: "DE"}, {
        label: "Österreich",
        value: "AT"
    }, {label: "Schweiz", value: "CH"}, {label: "Afghanistan", value: "AF"}, {
        label: "Ålandinseln",
        value: "AX"
    }, {label: "Albanien", value: "AL"}, {label: "Algerien", value: "DZ"}, {
        label: "Amerikanisch-Samoa",
        value: "AS"
    }, {label: "Andorra", value: "AD"}, {label: "Angola", value: "AO"}, {
        label: "Anguilla",
        value: "AI"
    }, {label: "Antarktis", value: "AQ"}, {label: "Antigua und Barbuda", value: "AG"}, {
        label: "Argentinien",
        value: "AR"
    }, {label: "Armenien", value: "AM"}, {label: "Aruba", value: "AW"}, {
        label: "Ascension",
        value: "AC"
    }, {label: "Aserbaidschan", value: "AZ"}, {label: "Australien", value: "AU"}, {
        label: "Bahamas",
        value: "BS"
    }, {label: "Bahrain", value: "BH"}, {label: "Bangladesch", value: "BD"}, {
        label: "Barbados",
        value: "BB"
    }, {label: "Belarus", value: "BY"}, {label: "Belgien", value: "BE"}, {
        label: "Belize",
        value: "BZ"
    }, {label: "Benin", value: "BJ"}, {label: "Bermuda", value: "BM"}, {
        label: "Bhutan",
        value: "BT"
    }, {label: "Bolivien", value: "BO"}, {
        label: "Bonaire, Sint Eustatius und Saba",
        value: "BQ"
    }, {label: "Bosnien und Herzegowina", value: "BA"}, {label: "Botsuana", value: "BW"}, {
        label: "Bouvetinsel",
        value: "BV"
    }, {label: "Brasilien", value: "BR"}, {
        label: "Britisches Territorium im Indischen Ozean",
        value: "IO"
    }, {label: "Britische Jungferninseln", value: "VG"}, {label: "Brunei Darussalam", value: "BN"}, {
        label: "Bulgarien",
        value: "BG"
    }, {label: "Burkina Faso", value: "BF"}, {label: "Burundi", value: "BI"}, {
        label: "Ceuta und Melilla",
        value: "EA"
    }, {label: "Chile", value: "CL"}, {label: "China", value: "CN"}, {
        label: "Clipperton-Insel",
        value: "CP"
    }, {label: "Cookinseln", value: "CK"}, {label: "Costa Rica", value: "CR"}, {
        label: "Côte d'Ivoire",
        value: "CI"
    }, {label: "Curaçao", value: "CW"}, {label: "Dänemark", value: "DK"}, {
        label: "Diego Garcia",
        value: "DG"
    }, {label: "Dominica", value: "DM"}, {label: "Dominikanische Republik", value: "DO"}, {
        label: "Dschibuti",
        value: "DJ"
    }, {label: "Ecuador", value: "EC"}, {label: "Ägypten", value: "EG"}, {
        label: "El Salvador",
        value: "SV"
    }, {label: "Äquatorialguinea", value: "GQ"}, {label: "Eritrea", value: "ER"}, {
        label: "Estland",
        value: "EE"
    }, {label: "Eswatini", value: "SZ"}, {label: "Äthiopien", value: "ET"}, {
        label: "Falklandinseln",
        value: "FK"
    }, {label: "Färöer", value: "FO"}, {label: "Fidschi", value: "FJ"}, {
        label: "Finnland",
        value: "FI"
    }, {label: "Frankreich", value: "FR"}, {
        label: "Französisch-Guayana",
        value: "GF"
    }, {label: "Französisch-Polynesien", value: "PF"}, {
        label: "Französische Süd- und Antarktisgebiete",
        value: "TF"
    }, {label: "Gabun", value: "GA"}, {label: "Gambia", value: "GM"}, {label: "Georgien", value: "GE"}, {
        label: "Ghana",
        value: "GH"
    }, {label: "Gibraltar", value: "GI"}, {label: "Griechenland", value: "GR"}, {
        label: "Grönland",
        value: "GL"
    }, {label: "Grenada", value: "GD"}, {label: "Guadeloupe", value: "GP"}, {
        label: "Guam",
        value: "GU"
    }, {label: "Guatemala", value: "GT"}, {label: "Guernsey", value: "GG"}, {
        label: "Guinea",
        value: "GN"
    }, {label: "Guinea-Bissau", value: "GW"}, {label: "Guyana", value: "GY"}, {
        label: "Haiti",
        value: "HT"
    }, {label: "Heard und McDonaldinseln", value: "HM"}, {label: "Honduras", value: "HN"}, {
        label: "Hongkong",
        value: "HK"
    }, {label: "Indien", value: "IN"}, {label: "Indonesien", value: "ID"}, {label: "Iran", value: "IR"}, {
        label: "Irak",
        value: "IQ"
    }, {label: "Irland", value: "IE"}, {label: "Isle of Man", value: "IM"}, {
        label: "Israel",
        value: "IL"
    }, {label: "Italien", value: "IT"}, {label: "Jamaika", value: "JM"}, {label: "Japan", value: "JP"}, {
        label: "Jemen",
        value: "YE"
    }, {label: "Jersey", value: "JE"}, {label: "Jordanien", value: "JO"}, {
        label: "Kaimaninseln",
        value: "KY"
    }, {label: "Kambodscha", value: "KH"}, {label: "Kamerun", value: "CM"}, {
        label: "Kanada",
        value: "CA"
    }, {label: "Kanarische Inseln", value: "IC"}, {label: "Kap Verde", value: "CV"}, {
        label: "Kasachstan",
        value: "KZ"
    }, {label: "Katar", value: "QA"}, {label: "Kenia", value: "KE"}, {
        label: "Kirgisistan",
        value: "KG"
    }, {label: "Kiribati", value: "KI"}, {label: "Kokosinseln", value: "CC"}, {
        label: "Kolumbien",
        value: "CO"
    }, {label: "Komoren", value: "KM"}, {
        label: "Kongo (Republik)",
        value: "CG"
    }, {label: "Kongo (Demokratische Republik)", value: "CD"}, {label: "Kosovo", value: "XK"}, {
        label: "Kroatien",
        value: "HR"
    }, {label: "Kuba", value: "CU"}, {label: "Kuwait", value: "KW"}, {label: "Laos", value: "LA"}, {
        label: "Lesotho",
        value: "LS"
    }, {label: "Lettland", value: "LV"}, {label: "Libanon", value: "LB"}, {
        label: "Liberia",
        value: "LR"
    }, {label: "Libyen", value: "LY"}, {label: "Liechtenstein", value: "LI"}, {
        label: "Litauen",
        value: "LT"
    }, {label: "Luxemburg", value: "LU"}, {label: "Macau", value: "MO"}, {
        label: "Madagaskar",
        value: "MG"
    }, {label: "Malawi", value: "MW"}, {label: "Malaysia", value: "MY"}, {
        label: "Malediven",
        value: "MV"
    }, {label: "Mali", value: "ML"}, {label: "Malta", value: "MT"}, {
        label: "Marokko",
        value: "MA"
    }, {label: "Marshallinseln", value: "MH"}, {label: "Martinique", value: "MQ"}, {
        label: "Mauretanien",
        value: "MR"
    }, {label: "Mauritius", value: "MU"}, {label: "Mayotte", value: "YT"}, {
        label: "Nordmazedonien",
        value: "MK"
    }, {label: "Mexiko", value: "MX"}, {label: "Mikronesien", value: "FM"}, {
        label: "Monaco",
        value: "MC"
    }, {label: "Mongolei", value: "MN"}, {label: "Montenegro", value: "ME"}, {
        label: "Montserrat",
        value: "MS"
    }, {label: "Mosambik", value: "MZ"}, {label: "Myanmar", value: "MM"}, {
        label: "Namibia",
        value: "NA"
    }, {label: "Nauru", value: "NR"}, {label: "Nepal", value: "NP"}, {
        label: "Neukaledonien",
        value: "NC"
    }, {label: "Neuseeland", value: "NZ"}, {label: "Niger", value: "NE"}, {
        label: "Nigeria",
        value: "NG"
    }, {label: "Niue", value: "NU"}, {label: "Nordkorea", value: "KP"}, {
        label: "Nördliche Marianen",
        value: "MP"
    }, {label: "Norfolkinsel", value: "NF"}, {label: "Norwegen", value: "NO"}, {
        label: "Oman",
        value: "OM"
    }, {label: "Osttimor", value: "TL"}, {label: "Pakistan", value: "PK"}, {
        label: "Palau",
        value: "PW"
    }, {label: "Palästinensische Autonomiegebiete", value: "PS"}, {
        label: "Panama",
        value: "PA"
    }, {label: "Papua-Neuguinea", value: "PG"}, {label: "Paraguay", value: "PY"}, {
        label: "Peru",
        value: "PE"
    }, {label: "Philippinen", value: "PH"}, {label: "Pitcairninseln", value: "PN"}, {
        label: "Polen",
        value: "PL"
    }, {label: "Portugal", value: "PT"}, {label: "Puerto Rico", value: "PR"}, {
        label: "Südkorea",
        value: "KR"
    }, {label: "Republik Moldau", value: "MD"}, {label: "Réunion", value: "RE"}, {
        label: "Ruanda",
        value: "RW"
    }, {label: "Rumänien", value: "RO"}, {label: "Russische Föderation", value: "RU"}, {
        label: "Saint-Barthélemy",
        value: "BL"
    }, {label: "Saint-Martin (französischer Teil)", value: "MF"}, {label: "Salomonen", value: "SB"}, {
        label: "Sambia",
        value: "ZM"
    }, {label: "Samoa", value: "WS"}, {label: "San Marino", value: "SM"}, {
        label: "São Tomé und Príncipe",
        value: "ST"
    }, {label: "Saudi-Arabien", value: "SA"}, {label: "Schweden", value: "SE"}, {
        label: "Senegal",
        value: "SN"
    }, {label: "Serbien", value: "RS"}, {label: "Seychellen", value: "SC"}, {
        label: "Sierra Leone",
        value: "SL"
    }, {label: "Simbabwe", value: "ZW"}, {
        label: "Singapur",
        value: "SG"
    }, {label: "Sint Maarten (niederländischer Teil)", value: "SX"}, {
        label: "Slowakei",
        value: "SK"
    }, {label: "Slowenien", value: "SI"}, {label: "Somalia", value: "SO"}, {
        label: "Spanien",
        value: "ES"
    }, {label: "Sri Lanka", value: "LK"}, {
        label: "St. Helena, Ascension und Tristan da Cunha",
        value: "SH"
    }, {label: "St. Kitts und Nevis", value: "KN"}, {
        label: "St. Lucia",
        value: "LC"
    }, {label: "Saint-Pierre und Miquelon", value: "PM"}, {
        label: "St. Vincent und die Grenadinen",
        value: "VC"
    }, {label: "Südafrika", value: "ZA"}, {
        label: "Sudan",
        value: "SD"
    }, {label: "Südgeorgien und die Südlichen Sandwichinseln", value: "GS"}, {
        label: "Südsudan",
        value: "SS"
    }, {label: "Suriname", value: "SR"}, {label: "Svalbard und Jan Mayen", value: "SJ"}, {
        label: "Syrien",
        value: "SY"
    }, {label: "Tadschikistan", value: "TJ"}, {label: "Taiwan", value: "TW"}, {
        label: "Tansania",
        value: "TZ"
    }, {label: "Thailand", value: "TH"}, {label: "Togo", value: "TG"}, {label: "Tokelau", value: "TK"}, {
        label: "Tonga",
        value: "TO"
    }, {label: "Trinidad und Tobago", value: "TT"}, {label: "Tristan da Cunha", value: "TA"}, {
        label: "Tschad",
        value: "TD"
    }, {label: "Tschechien", value: "CZ"}, {label: "Tunesien", value: "TN"}, {
        label: "Türkei",
        value: "TR"
    }, {label: "Turkmenistan", value: "TM"}, {label: "Turks- und Caicosinseln", value: "TC"}, {
        label: "Tuvalu",
        value: "TV"
    }, {label: "Uganda", value: "UG"}, {label: "Ukraine", value: "UA"}, {
        label: "Ungarn",
        value: "HU"
    }, {label: "United States Minor Outlying Islands", value: "UM"}, {
        label: "Uruguay",
        value: "UY"
    }, {label: "Usbekistan", value: "UZ"}, {label: "Vanuatu", value: "VU"}, {
        label: "Vatikanstadt",
        value: "VA"
    }, {label: "Venezuela", value: "VE"}, {
        label: "Vereinigte Arabische Emirate",
        value: "AE"
    }, {label: "Vereinigtes Königreich", value: "GB"}, {label: "Vietnam", value: "VN"}, {
        label: "Wallis und Futuna",
        value: "WF"
    }, {label: "Weihnachtsinsel", value: "CX"}, {
        label: "Westsahara",
        value: "EH"
    }, {label: "Zentralafrikanische Republik", value: "CF"}, {label: "Zypern", value: "CY"}];


    function findCountryLabelByValue(targetValue) {
        const country = countryPickerItems.find(country => country.value === targetValue);
        return country ? country.label : null;
    }

    const [pickerOpen, setPickerOpen] = useState(false);
    const [land, setLand] = useState("DE");

    const [anrede, setAnrede] = useState("M");
    const [anzahl, setAnzahl] = useState(10);
    const [vorname, setVorname] = useState("");
    const [titel, setTitel] = useState("");
    const [nachname, setNachname] = useState("");
    const [beruf, setBeruf] = useState("");
    const [stadt, setStadt] = useState("");
    const [plz, setPlz] = useState("");
    const [strasse, setStrasse] = useState("");
    const [hausnummer, setHausnummer] = useState("");
    const [email, setEmail] = useState("");
    const [telefon, setTelefon] = useState("");


    const [errorMsgAnrede, setErrorMsgAnrede] = useState("");
    const [errorMsgAnzahl, setErrorMsgAnzahl] = useState("");
    const [errorMsgVorname, setErrorMsgVorname] = useState("");
    const [errorMsgTitel, setErrorMsgTitel] = useState("");
    const [errorMsgNachname, setErrorMsgNachname] = useState("");
    const [errorMsgBeruf, setErrorMsgBeruf] = useState("");
    const [errorMsgStadt, setErrorMsgStadt] = useState("");
    const [errorMsgPLZ, setErrorMsgPlz] = useState("");
    const [errorMsgStrasse, setErrorMsgStrasse] = useState("");
    const [errorMsgHausnummer, setErrorMsgHausnummer] = useState("");
    const [errorMsgEmail, setErrorMsgEmail] = useState("");
    const [errorMsgTelefon, setErrorMsgTelefon] = useState("");
    const [errorMsgCheck, setErrorMsgCheck] = useState("");

    const inputAnrede = useRef();
    const inputAnzahl = useRef();
    const inputVorname = useRef();
    const inputTitel = useRef();
    const inputNachname = useRef();
    const inputBeruf = useRef();
    const inputStadt = useRef();
    const inputPLZ = useRef();
    const inputStrasse = useRef();
    const inputHausnummer = useRef();
    const inputEmail = useRef();
    const inputTelefon = useRef();
    const inputCheck = useRef();

    const [check1, setCheck1] = useState(false);

    const dispatch = useDispatch();

    const [msg, setMsg] = useState({});
    const [showMsg, setShowMsg] = useState(false);

    const handleConfirm = () => {
        // setShowConfirmModal(false);

        let itemString = '<flyer_anrede>' + anrede + '</flyer_anrede>';
        itemString += '<flyer_titel>' + titel + '</flyer_titel>';
        itemString += '<flyer_vorname>' + vorname + '</flyer_vorname>';
        itemString += '<flyer_nachname>' + nachname + '</flyer_nachname>';
        itemString += '<flyer_beruf>' + beruf + '</flyer_beruf>';
        itemString += '<flyer_stadt>' + stadt + '</flyer_stadt>';
        itemString += '<flyer_plz>' + plz + '</flyer_plz>';
        itemString += '<flyer_strasse>' + strasse + ' ' + hausnummer + '</flyer_strasse>';
        itemString += '<flyer_land>' + land + '</flyer_land>';
        itemString += '<flyer_email>' + email + '</flyer_email>';
        itemString += '<flyer_telefon>' + telefon + '</flyer_telefon>';
        itemString += '<flyer_anzahl>' + anzahl + '</flyer_anzahl>';

        showMessage(
            {
                message: "\n\nEinen kurzen Moment... Ihre Daten werden abgeschickt.\n\n",
                position: "bottom",
                type: "info",
                autoHide: false,
                floating: true
            },
            [msg, setMsg],
            [showMsg, setShowMsg]
        );

        loggedInClient.importRecord('AATMSYSTEM', 'flyerbestellung', itemString)
            .then(() => {
                showMessage(
                    {
                        message: "\n\nVielen Dank! Wir nehmen Ihren Auftrag zur Flyerbestellung gerne entgegen.\n\n",
                        position: "bottom",
                        type: "success",
                        autoHide: true,
                        duration: 2500,
                        floating: true
                    },
                    [msg, setMsg],
                    [showMsg, setShowMsg]
                );

                setTimeout(() => navigation.goBack(), 2500)

                dispatch(
                    setIsRegistered(true)
                );

            }).catch((e) => {
            showMessage(
                {
                    message: "\n\nEin Fehler ist beim Abschicken der Daten aufgetreten. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.\n\n",
                    position: "bottom",
                    type: "danger",
                    autoHide: false,
                    floating: true
                },
                [msg, setMsg],
                [showMsg, setShowMsg]
            );
            dispatch(
                setIsRegistered(false)
            );
        });
    };

    const ConfirmModal = ({visible, onDismiss, onConfirm}) => {
        const handleOkPress = () => {
            onConfirm();
            onDismiss();
        };

        return (
            <Provider style={{alignContent: 'center', alignItems: 'center', alignSelf: 'center', background: 'red'}}>
                <Portal
                    style={{alignContent: 'center', alignItems: 'center', alignSelf: 'center', background: 'black'}}>
                    <Modal visible={visible} onDismiss={onDismiss} transparent={true}>
                        <View style={localStyle.modalOverlay}/>

                        <View style={localStyle.modalContainer}>
                            <Text style={localStyle.modalMessage}>
                                Bitte prüfen Sie die folgenden Angaben:
                            </Text>

                            <View style={localStyle.modalMessage}>
                                <ScrollView>
                                    <View style={{flex: 1}}>

                                        <Text style={{fontWeight: 'bold'}}>Anzahl Infoflyer zur Bestellung:</Text>
                                        <Text>{anzahl}{"\n\n"}</Text>

                                        <Text style={{fontWeight: 'bold'}}>Anrede:</Text>

                                        {anrede === 'M' && <Text>Frau{"\n"}</Text>}
                                        {anrede === 'V' && <Text>Herr{"\n"}</Text>}
                                        {anrede === 'D' && <Text>Divers{"\n"}</Text>}

                                        <Text style={{fontWeight: 'bold'}}>{"\n"}Titel:</Text>
                                        <Text>{titel}{"\n\n"}</Text>

                                        <Text style={{fontWeight: 'bold'}}>Vor- und Nachname:</Text>
                                        <Text>{vorname} {nachname}{"\n\n"}</Text>


                                        <Text style={{fontWeight: 'bold'}}>Beruf:</Text>
                                        <Text>{beruf}{"\n\n"}</Text>

                                        <Text style={{fontWeight: 'bold'}}>Versandadresse:</Text>
                                        <Text>{strasse} {hausnummer}</Text>
                                        <Text>{plz} {stadt}</Text>
                                        <Text>{findCountryLabelByValue(land)}{"\n\n"}</Text>

                                        <Text style={{fontWeight: 'bold'}}>E-Mail-Adresse:</Text>
                                        <Text>{email}{"\n\n"}</Text>

                                        <Text style={{fontWeight: 'bold'}}>Telefonnummer (für evtl. Rückfragen):</Text>
                                        <Text>{telefon}</Text>
                                    </View>
                                </ScrollView>


                            </View>

                            <View style={{
                                flexDirection: 'row'
                            }}>
                                <TouchableOpacity
                                    style={[localStyle.buttonOutlined, {width: '50%'}]}
                                    onPress={() => {
                                        onDismiss()
                                    }}
                                >
                                    <Text style={localStyle.buttonLabelOutlined}>
                                        Eingabe ändern
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[localStyle.buttonSubmit, {width: '50%'}]}
                                    onPress={handleOkPress}
                                >
                                    <Text style={localStyle.buttonLabel}>
                                        Abschicken
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Modal>
                </Portal>
            </Provider>
        );
    };


    const SubmittedModal = ({visible, onDismiss, onConfirm}) => {
        const handleOkPress = () => {
            onConfirm();
            onDismiss();
        };

        return (
            <Provider style={{alignContent: 'center', alignItems: 'center', alignSelf: 'center', background: 'red'}}>
                <Portal
                    style={{alignContent: 'center', alignItems: 'center', alignSelf: 'center', background: 'black'}}>
                    <Modal style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        flexDirection: 'row',
                        background: 'blue'
                    }} visible={visible} onDismiss={onDismiss} transparent={true}>
                        <View style={localStyle.modalOverlay}/>

                        <View style={localStyle.modalContainer}>
                            <Text style={localStyle.modalMessage}>
                                Wir haben Ihre Daten erhalten - vielen Dank dafür!
                                Nach eingehender Prüfung Ihrer Daten schicken wir Ihnen Ihre Zugangsdaten postalisch zu.
                            </Text>

                            <TouchableOpacity
                                style={[localStyle.buttonSubmit, {width: '50%'}]}
                                onPress={handleOkPress}
                            >
                                <Text style={localStyle.buttonLabel}>
                                    OK
                                </Text>
                            </TouchableOpacity>


                        </View>
                    </Modal>
                </Portal>
            </Provider>
        );
    };

    const {width} = useWindowDimensions();


    return (
        <View style={{backgroundColor: 'white'}}>
            <View style={{width: 800}}>
                {/*<Banner*/}
                {/*    nav={navigation}*/}
                {/*    title={"Registrierung"}*/}
                {/*    subTitle={translate('login').subTitle}*/}
                {/*    noWayBack={true}*/}
                {/*/>*/}
                <ScrollIndicatorWrapper useKeyboardAware={true} style={localStyle.wrapper}>
                    <View>
                        {/* content */}

                        {/*<Image*/}
                        {/*    source={require("../assets/images/illu-registrierung.png")}*/}
                        {/*    resizeMode="contain"*/}
                        {/*    style={localStyle.logo}*/}
                        {/*></Image>*/}

                        <Text style={localStyle.title}>
                            Informationen für Medizinisches Fachpersonal
                        </Text>
                        <Text style={localStyle.infoText}>
                            Sollten Sie medizinischem Fachpersonal angehören, das Patienten und Patientinnen mit AATM
                            auf das Alpha-1-KIDS-Register hinweisen möchte, können Sie hier kostenfrei Informationsflyer
                            bestellen.
                        </Text>

                        <View style={[localStyle.item, {marginTop: 30}]}>
                            <View>
                                <Text style={localStyle.title}>Anzahl Infoflyer</Text>
                            </View>
                            <View>
                                <RadioButton.Group onValueChange={
                                    newValue => {
                                        setAnzahl(newValue);
                                    }
                                } value={anzahl}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        {[10, 20, 50, 100].map(v => {
                                            return (<TouchableOpacity onPress={() => {
                                                setAnzahl(v)
                                                // setTimeout(()=> inputVorname.current?.focus(), 500);
                                            }} style={{
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                marginRight: 35
                                            }}>
                                                <RadioButton.Android
                                                    labelStyle={{textAlign: 'left'}}
                                                    status={anrede === v}
                                                    value={v}
                                                />
                                                <Text style={localStyle.optionText}>{v}</Text>
                                            </TouchableOpacity>);
                                        })}
                                    </View>
                                </RadioButton.Group>
                                {/*<TextInput*/}
                                {/*    style={{*/}
                                {/*        textAlign: 'left',*/}
                                {/*        backgroundColor: theme.colors.white*/}
                                {/*    }}*/}
                                {/*    error={!!errorMsgAnrede}*/}
                                {/*    ref={inputAnrede}*/}
                                {/*    enterKeyHint={"next"}*/}
                                {/*    returnKeyType={"next"}*/}
                                {/*    onSubmitEditing={() => { inputVorname.current?.focus(); }}*/}
                                {/*    onChangeText={text => setAnrede(text)}*/}
                                {/*    contentStyle={{...theme.fonts.input}}*/}
                                {/*    underlineColor={theme.colors.primary}*/}
                                {/*    activeUnderlineColor={theme.colors.primary}*/}
                                {/*    placeholder={"Bitte Anrede angeben"}/>*/}
                                {!!errorMsgAnrede && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgAnrede}</Text>}
                            </View>
                        </View>

                        <View style={[localStyle.item, {marginTop: 30}]}>
                            <View>
                                <Text style={localStyle.title}>Anrede</Text>
                            </View>
                            <View>
                                <RadioButton.Group onValueChange={
                                    newValue => {
                                        setAnrede(newValue);
                                        // inputVorname.current?.focus()
                                    }
                                } value={anrede}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <TouchableOpacity onPress={() => {
                                            setAnrede('M')
                                            // setTimeout(()=> inputVorname.current?.focus(), 500);
                                        }} style={{flexDirection: 'row', alignItems: 'flex-start', marginRight: 35}}>
                                            <RadioButton.Android
                                                labelStyle={{textAlign: 'left'}}
                                                status={anrede === 'M'}
                                                value="M"
                                            />
                                            <Text style={localStyle.optionText}>Frau</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setAnrede('V')
                                            // setTimeout(()=> inputVorname.current?.focus(), 500);
                                        }} style={{flexDirection: 'row', alignItems: 'flex-start', marginRight: 35}}>
                                            <RadioButton.Android
                                                labelStyle={{textAlign: 'left'}}
                                                status={anrede === 'V'}
                                                value="V"
                                            />
                                            <Text style={localStyle.optionText}>Herr</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setAnrede('D')
                                            // setTimeout(()=> inputVorname.current?.focus(), 500);
                                        }} style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                                            <RadioButton.Android
                                                labelStyle={{textAlign: 'left'}}
                                                status={anrede === 'D'}
                                                value="D"
                                            />
                                            <Text style={localStyle.optionText}>Divers</Text>
                                        </TouchableOpacity>
                                    </View>

                                </RadioButton.Group>
                                {/*<TextInput*/}
                                {/*    style={{*/}
                                {/*        textAlign: 'left',*/}
                                {/*        backgroundColor: theme.colors.white*/}
                                {/*    }}*/}
                                {/*    error={!!errorMsgAnrede}*/}
                                {/*    ref={inputAnrede}*/}
                                {/*    enterKeyHint={"next"}*/}
                                {/*    returnKeyType={"next"}*/}
                                {/*    onSubmitEditing={() => { inputVorname.current?.focus(); }}*/}
                                {/*    onChangeText={text => setAnrede(text)}*/}
                                {/*    contentStyle={{...theme.fonts.input}}*/}
                                {/*    underlineColor={theme.colors.primary}*/}
                                {/*    activeUnderlineColor={theme.colors.primary}*/}
                                {/*    placeholder={"Bitte Anrede angeben"}/>*/}
                                {!!errorMsgAnrede && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgAnrede}</Text>}
                            </View>
                        </View>

                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Titel</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgTitel}
                                    ref={inputTitel}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputVorname.current?.focus();
                                    }}
                                    onChangeText={text => {
                                        setTitel(text)
                                    }}
                                    contentStyle={{...theme.fonts.input}}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Titel eingeben"}/>
                                {!!errorMsgTitel && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgTitel}</Text>}
                            </View>
                        </View>


                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Vorname</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgVorname}
                                    ref={inputVorname}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputNachname.current?.focus();
                                    }}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgVorname(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgVorname("");
                                        }
                                        setVorname(text)
                                    }}
                                    contentStyle={{...theme.fonts.input}}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Vornamen eingeben"}/>
                                {!!errorMsgVorname && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgVorname}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Nachname</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgNachname}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputStrasse.current?.focus();
                                    }}
                                    ref={inputNachname}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgNachname(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgNachname("");
                                        }
                                        setNachname(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Nachnamen eingeben"}/>
                                {!!errorMsgNachname && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgNachname}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Beruf</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgBeruf}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputStrasse.current?.focus();
                                    }}
                                    ref={inputBeruf}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgBeruf(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgBeruf("");
                                        }
                                        setBeruf(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Beruf eingeben"}/>
                                {!!errorMsgBeruf && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgBeruf}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Straße</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgStrasse}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputHausnummer.current?.focus();
                                    }}
                                    ref={inputStrasse}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgStrasse(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgStrasse("");
                                        }
                                        setStrasse(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Straße eingeben"}/>
                                {!!errorMsgStrasse && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgStrasse}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Hausnummer</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgHausnummer}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputStadt.current?.focus();
                                    }}
                                    ref={inputHausnummer}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgHausnummer(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgHausnummer("");
                                        }
                                        setHausnummer(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Hausnummer eingeben"}/>
                                {!!errorMsgHausnummer && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgHausnummer}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Stadt</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgStadt}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputPLZ.current?.focus();
                                    }}
                                    ref={inputStadt}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgStadt(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgStadt("");
                                        }
                                        setStadt(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Stadt eingeben"}/>
                                {!!errorMsgStadt && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgStadt}</Text>}

                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Postleitzahl</Text>
                            </View>
                            <View>

                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgPLZ}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {

                                    }}
                                    ref={inputPLZ}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgPlz(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgPlz("");
                                        }
                                        setPlz(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Postleitzahl eingeben"}/>
                                {!!errorMsgPLZ && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgPLZ}</Text>}
                            </View>
                        </View>


                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Land</Text>
                            </View>
                            <View>
                                <DropDownPicker
                                    open={pickerOpen}
                                    setOpen={setPickerOpen}
                                    value={land}
                                    setValue={setLand}
                                    items={countryPickerItems}
                                    listMode={countryPickerItems.length > 6 ? "MODAL" : "FLATLIST"}

                                    textStyle={{
                                        fontSize: 18
                                    }}
                                    placeholder={"Wählen Sie aus der Liste aus..."}
                                />
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>E-Mail-Adresse</Text>
                            </View>
                            <View>
                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgEmail}
                                    enterKeyHint={"next"}
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => {
                                        inputTelefon.current?.focus();
                                    }}
                                    ref={inputEmail}
                                    keyboardType={'email-address'}
                                    onChangeText={text => {
                                        if (!validator.isEmail(text)) {
                                            setErrorMsgEmail(translate('survey').invalidNotEmail);
                                        } else {
                                            setErrorMsgEmail("");
                                        }
                                        setEmail(text);
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte E-Mail-Adresse eingeben"}/>
                                {!!errorMsgEmail && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgEmail}</Text>}
                            </View>
                        </View>
                        <View style={localStyle.item}>
                            <View>
                                <Text style={localStyle.title}>Telefonnummer (für evtl. Rückfragen)</Text>
                            </View>
                            <View>

                                <TextInput
                                    style={{
                                        textAlign: 'left',
                                        backgroundColor: theme.colors.white
                                    }}
                                    error={!!errorMsgHausnummer}
                                    enterKeyHint={"done"}
                                    returnKeyType={"done"}
                                    onSubmitEditing={() => {
                                        inputCheck.current?.focus();
                                    }}
                                    ref={inputTelefon}
                                    keyboardType={'phone-pad'}
                                    onChangeText={text => {
                                        if (validator.isEmpty(text)) {
                                            setErrorMsgTelefon(translate('survey').invalidEmpty);
                                        } else {
                                            setErrorMsgTelefon("");
                                        }
                                        setTelefon(text)
                                    }}
                                    underlineColor={theme.colors.primary}
                                    activeUnderlineColor={theme.colors.primary}
                                    placeholder={"Bitte Telefonnummer eingeben"}/>
                                {!!errorMsgTelefon && <Text style={{
                                    marginTop: 4,
                                    color: theme.colors.no,
                                    fontWeight: 'bold'
                                }}>{errorMsgTelefon}</Text>}
                            </View>
                        </View>


                        <View style={{flexDirection: 'row', width: '90%'}}>
                            <Text style={[localStyle.infoText, localStyle.smallInfoText, {textAlign: 'left'}]}>
                                Ihre Daten werden auf Servern des Universitätsklinikum Bonn gespeichert. Ausschließlich
                                die
                                für das Register verantwortlichen Personen bzw. von ihnen persönlich ernannte
                                Mitarbeiter,
                                die
                                Sie bei
                                der Administration des Projektes unterstützen, haben Zugriff auf den vollständigen
                                Datensatz
                                einschließlich Ihres
                                Namens und Ihrer Anschrift.
                            </Text></View>


                        <View style={{flexDirection: 'row', width: '80%', marginTop: 20}}>

                            <CheckBox
                                textStyle={SharedStyles.choiceText}
                                checkedColor={theme.colors.accent4}
                                uncheckedColor={theme.colors.accent1}
                                containerStyle={{
                                    ...SharedStyles.choice,
                                    // marginLeft: calculateIndent(item.linkId),
                                }}
                                checked={check1}
                                onIconPress={() => setCheck1(!check1)}
                            />
                            <RenderHtml
                                contentWidth={width}
                                source={{
                                    html: '<div style="font-size: 1.2em; width: 700px; text-align: justify;">' +
                                        'Bitte schicken Sie uns die ausgewählte Anzahl an Infoflyern zum Alpha-1-KIDS Register für Patienten und Patientinnen an die genannte Versandadresse kostenfrei zu.' +
                                        '</div>'
                                }}
                            />
                        </View>

                    </View>


                    {!!errorMsgCheck && <Text style={{
                        marginTop: 20,
                        color: theme.colors.no,
                        fontWeight: 'bold'
                    }}
                                              ref={inputCheck}>{errorMsgCheck}</Text>
                    }

                    <ConfirmModal visible={showConfirmModal} onDismiss={() => setShowConfirmModal(false)}
                                  onConfirm={handleConfirm}/>


                    {showMsg && <View style={[
                        localStyle.message,
                        {background: msg?.backgroundColor ?? 'white'}]}>
                        <Text style={{color: msg?.textStyle?.textColor ?? 'white'}}>{msg.message}</Text>
                    </View>}
                    <View
                        style={{
                            flexDirection: 'row',
                            marginTop: 30,
                            marginBottom: 30,
                        }}>
                        <TouchableOpacity
                            style={[localStyle.buttonOutlined, {width: '50%'}]}
                            onPress={() => navigation.goBack()}
                        >
                            <Text style={localStyle.buttonLabelOutlined}>Abbrechen</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[localStyle.buttonSubmit, {width: '50%'}]}
                            onPress={() => {

                                setErrorMsgAnrede("");
                                setErrorMsgTitel("");
                                setErrorMsgNachname("");
                                setErrorMsgStadt("");
                                setErrorMsgPlz("");
                                setErrorMsgStrasse("");
                                setErrorMsgHausnummer("");
                                setErrorMsgEmail("");
                                setErrorMsgTelefon("");
                                setErrorMsgCheck("");

                                let focusRef = null;
                                if (validator.isEmpty(anrede)) {
                                    setErrorMsgAnrede(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputAnrede.current;
                                    }
                                }
                                if (validator.isEmpty(vorname)) {
                                    setErrorMsgVorname(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputVorname.current;
                                    }
                                }
                                if (validator.isEmpty(nachname)) {
                                    setErrorMsgNachname(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputNachname.current;
                                    }
                                }
                                if (validator.isEmpty(beruf)) {
                                    setErrorMsgBeruf(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputBeruf.current;
                                    }
                                }
                                if (validator.isEmpty(stadt)) {
                                    setErrorMsgStadt(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputStadt.current;
                                    }
                                }
                                if (validator.isEmpty(plz)) {
                                    setErrorMsgPlz(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputPLZ.current;
                                    }
                                }
                                if (validator.isEmpty(strasse)) {
                                    setErrorMsgStrasse(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputStrasse.current;
                                    }
                                }
                                if (validator.isEmpty(hausnummer)) {
                                    setErrorMsgHausnummer(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputHausnummer.current;
                                    }
                                }
                                console.log('EMAILCHECK1')
                                if (!validator.isEmail(email)) {
                                    console.log('EMAILCHECK2')
                                    setErrorMsgEmail(translate('survey').invalidNotEmail);
                                    if (!focusRef) {
                                        focusRef = inputEmail.current;
                                    }
                                }
                                if (validator.isEmpty(telefon)) {
                                    setErrorMsgTelefon(translate('survey').invalidEmpty);
                                    if (!focusRef) {
                                        focusRef = inputTelefon.current;
                                    }
                                }

                                if (focusRef) {
                                    focusRef.focus();
                                    return;
                                }


                                if (!check1) {
                                    setErrorMsgCheck("Bitte bestätigen Sie die Bestellung der angegebenen Anzahl von Flyern. Die Bestellung erfolgt kostenlos. ");
                                    return;
                                }

                                setShowConfirmModal(true)
                            }
                            }
                        >
                            <Text style={localStyle.buttonLabel}>Bestellung aufgeben</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollIndicatorWrapper>
            </View>
        </View>
    );
}

MedicalStaffScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        // paddingTop: appConfig.scaleUiFkt(30),
        paddingBotton: appConfig.scaleUiFkt(20),
        paddingLeft: appConfig.scaleUiFkt(20),
        paddingRight: appConfig.scaleUiFkt(20),
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        ...theme.fonts.title2,
        marginBottom: appConfig.scaleUiFkt(3)
    },
    logo: {
        height: 250,
    },
    button: {
        marginTop: appConfig.scaleUiFkt(5),
        marginStart: appConfig.scaleUiFkt(30),
        marginEnd: appConfig.scaleUiFkt(30)
    },
    buttonRegister: {
        marginBottom: appConfig.scaleUiFkt(10),
    },
    infoText: {
        ...theme.fonts.body,
        marginVertical: appConfig.scaleUiFkt(5),
        textAlign: 'justify',
        // alignSelf: 'center',
        color: theme.colors.accent4,

    },
    smallInfoText: {
        //      fontSize: appConfig.scaleUiFkt(14),
        //  lineHeight: appConfig.scaleUiFkt(15),
    },
    optionText: {
        ...theme.fonts.body,
        marginVertical: appConfig.scaleUiFkt(2),
        textAlign: 'justify',
        // alignSelf: 'center',
        color: theme.colors.accent4,
    },

    modalContainer: {
        backgroundColor: '#fff',
        padding: 20,
        left: '50%',
        marginLeft: -400,
        top: '5%',

        borderRadius: 10,
        position: 'absolute',
        width: 800, height: 500
    },

    modalOverlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    modalMessage: {
        fontSize: 18,
        marginBottom: 20,
    },

    item: {
        marginStart: 10,
        marginEnd: 10,
        marginBottom: 30,
    },
    itemLabel: {
        fontSize: 14,
        marginBottom: 5
    },

    buttonSubmit: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        marginRight: 10
    },

    buttonOutlined: {
        ...theme.classes.buttonPrimaryOutlined,
        bottom: 0,
        marginRight: 10
    },
    buttonLabel: {
        ...theme.classes.buttonLabel,
        color: theme.colors.white
    },
    buttonLabelOutlined: {
        ...theme.classes.buttonLabel,
        color: theme.colors.primary
    },

    message: {
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 8,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default MedicalStaffScreen;

